import Home from "@/views/index.vue";
import login from "@/views/login.vue";
import notpath from "@/views/lose.vue";
// 
import store from "../store";
console.log('打印store数据：----', store.state);
const routes = [
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      title: "管理员登录",
    },
  },
  {
    path: "/",
    name: "home",
    component: Home,
    redirect: "/index",
    children: [
      // Echarts图表示例 和 导出表格
      {
        path: "/index",
        name: "index",
        meta: {
          group: "monitor",
          groupName: "首页",
          name: "数据概览",
          icon: "Histogram",
          info: "monitor/首页/数据概览",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/home/home.vue"),
      },
      {
        path: "/sysconfig",
        name: "sysconfig",
        meta: {
          group: "monitor",
          groupName: "配置管理",
          name: "全局配置",
          icon: "Sunset",
          info: "monitor/配置管理/全局配置",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/setting/config.vue"),
      },
      // {
      //   path: "/excel",
      //   name: "excel",
      //   meta: {
      //     group: "monitor",
      //     groupName: "",
      //     name: "代理商订单",
      //     icon: "Printer",
      //     info: "monitor/首页/代理商订单",
      //     keepAlive: false,
      //   },
      //   component: () => import("@/views/page/home/excel.vue"),
      // },



      // 商城端，会员管理
      {
        path: "/user",
        name: "user",
        meta: {
          group: "User",
          groupName: "会员管理",
          name: "会员列表",
          icon: "UserFilled",
          info: "User/会员管理/会员列表",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/user/user.vue"),
      },
      {
        path: "/audit",
        name: "audit",
        meta: {
          group: "User",
          groupName: "会员管理",
          name: "实名认证",
          icon: "Checked",
          info: "User/会员管理/实名认证",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/user/identifyAudit.vue"),
      },
      {
        path: "/actconf",
        name: "actconf",
        meta: {
          group: "User",
          groupName: "会员管理",
          name: "会员优惠券",
          icon: "Ticket",
          info: "User/会员管理/会员优惠券",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/user/actconf.vue"),
      },
      // 资产管理
      {
        path: "/apply",
        name: "apply",
        meta: {
          group: "User",
          groupName: "会员管理",
          name: "提现审核",
          icon: "Management",
          info: "User/会员管理/提现审核",
          keepAlive: true,
          role: "admin"
        },
        component: () => import("@/views/page/wallet/apply.vue"),
      },




      // 会员商品管理
      {
        path: "/goods",
        name: "goods",
        meta: {
          group: "Present",
          groupName: "商品管理",
          name: "商品列表",
          icon: "Goods",
          info: "Box/商品管理/商品列表",
          keepAlive: false,
          role: "admin/kuguan"
        },
        component: () => import("@/views/page/goods/list.vue"),
      },
      {
        path: "/addgoods",
        name: "addgoods",
        meta: {
          group: "Present",
          groupName: "商品管理",
          name: "新增商品",
          icon: "CirclePlus",
          info: "Box/商品管理/新增商品",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/goods/addgoods.vue"),
      },
      {
        path: "/supplier",
        name: "supplier",
        meta: {
          group: "Present",
          groupName: "商品管理",
          name: "品牌管理",
          icon: "Star",
          info: "Box/商品管理/品牌管理",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/goods/supplier.vue"),
      },
      {
        path: "/typesVip",
        name: "typesVip",
        meta: {
          group: "Present",
          groupName: "商品管理",
          name: "专区管理",
          icon: "TurnOff",
          info: "Box/商品管理/专区管理",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/goods/types.vue"),
      },
      {
        path: "/classifyVip",
        name: "classifyVip",
        meta: {
          group: "Present",
          groupName: "商品管理",
          name: "类别管理",
          icon: "Notification",
          info: "Box/商品管理/类别管理",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/goods/classify.vue"),
      },
      {
        path: "/specVip",
        name: "specVip",
        meta: {
          group: "Present",
          groupName: "商品管理",
          name: "规格管理",
          icon: "Menu",
          info: "Box/商品管理/规格管理",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/goods/spec.vue"),
      },
      {
        path: "/logistics",
        name: "logistics",
        meta: {
          group: "Present",
          groupName: "商品管理",
          name: "物流模板",
          icon: "Van",
          info: "Box/商品管理/物流模板",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/goods/logistics.vue"),
      },
      // {
      //   path: "/goodslable",
      //   name: "goodslable",
      //   meta: {
      //     group: "Box",
      //     groupName: "商品管理",
      //     name: "标签管理",
      //     icon: "Menu",
      //     info: "Box/商品管理/标签管理",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/goods/lable.vue"),
      // },

      // {
      //   path: "/brand",
      //   name: "brand",
      //   meta: {
      //     group: "Box",
      //     groupName: "商品管理",
      //     name: "品牌管理",
      //     icon: "Connection",
      //     info: "Box/商品管理/品牌管理",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/goods/brand.vue"),
      // },
      // 活动管理
      {
        path: "/activetime",
        name: "activetime",
        meta: {
          group: "Flag",
          groupName: "活动管理",
          name: "返分活动",
          icon: "Timer",
          info: "Flag/活动管理/返积分活动",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/active/actives.vue"),
      },
      // 积分
      {
        path: "/integral",
        name: "integral",
        meta: {
          group: "Flag",
          groupName: "商品管理",
          name: "积分专区",
          icon: "Coin",
          info: "Box/商品管理/积分专区",
          keepAlive: true,
          role: "admin"
        },
        component: () => import("@/views/page/active/integral.vue"),
      },

      // 订单管理
      {
        path: "/order",
        name: "order",
        meta: {
          group: "Notebook",
          groupName: "订单管理",
          name: "订单列表",
          icon: "List",
          info: "Notebook/订单管理/订单列表",
          keepAlive: false,
          role: "admin/kuguan"
        },
        component: () => import("@/views/page/order/order.vue"),
      },
      // 发货单
      {
        path: "/ordergoods",
        name: "ordergoods",
        meta: {
          group: "Notebook",
          groupName: "订单管理",
          name: "导出发货单",
          icon: "Collection",
          info: "Notebook/订单管理/导出发货单",
          keepAlive: false,
          role: "admin/kuguan"
        },
        component: () => import("@/views/page/order/ordergoods.vue"),
      },
      // {
      //   path: "/orderexchange",
      //   name: "orderexchange",
      //   meta: {
      //     group: "Notebook",
      //     groupName: "商城订单",
      //     name: "兑换订单",
      //     icon: "Collection",
      //     info: "Notebook/商城订单/兑换订单",
      //     keepAlive: false,
      //     role: "admin/kuguan"
      //   },
      //   component: () => import("@/views/page/order/orderexchange.vue"),
      // },
      // 采购订单管理
      // {
      //   path: "/purchaseorder",
      //   name: "purchaseorder",
      //   meta: {
      //     group: "Notebook",
      //     groupName: "采购订单",
      //     name: "订单列表",
      //     icon: "List",
      //     info: "Notebook/采购订单/订单列表",
      //     keepAlive: false,
      //     role: "admin/caiwu/kuguan"
      //   },
      //   component: () => import("@/views/page/purchaseorder/order.vue"),
      // },

      // {
      //   path: "/ordergoods",
      //   name: "ordergoods",
      //   meta: {
      //     group: "Management",
      //     groupName: "商城订单",
      //     name: "导出发货单",
      //     icon: "Collection",
      //     info: "Management/商城订单/导出发货单",
      //     keepAlive: false,
      //     role: "admin/kuguan"
      //   },
      //   component: () => import("@/views/page/order/ordergoods.vue"),
      // },
      // {
      //   path: "/afterorder",
      //   name: "afterorder",
      //   meta: {
      //     group: "Collection",
      //     groupName: "订单管理",
      //     name: "售后订单",
      //     icon: "DocumentDelete",
      //     info: "Collection/订单管理/售后订单",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/order/afterorder.vue"),
      // },


      // 资产管理
      //   {
      //     path: "/apply",
      //     name: "apply",
      //     meta: {
      //       group: "Wallet",
      //       groupName: "资产管理",
      //       name: "提现审核",
      //       icon: "Management",
      //       info: "Wallet/资产管理/提现审核",
      //       keepAlive: true,
      // role: "admin"
      //     },
      //     component: () => import("@/views/page/wallet/apply.vue"),
      //   },


      // 文章管理
      // {
      //   path: "/article",
      //   name: "article",
      //   meta: {
      //     group: "Tickets",
      //     groupName: "文章管理",
      //     name: "资讯列表",
      //     icon: "Document",
      //     info: "Tickets/文章管理/资讯列表",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/article/article.vue"),
      // },
      // {
      //   path: "/articleclass",
      //   name: "articleclass",
      //   meta: {
      //     group: "Tickets",
      //     groupName: "文章管理",
      //     name: "文章分类",
      //     icon: "ScaleToOriginal",
      //     info: "Tickets/文章管理/文章分类",
      //     keepAlive: false,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/article/articleClass.vue"),
      // },
      // {
      //   path: "/notify",
      //   name: "notify",
      //   meta: {
      //     group: "Tickets",
      //     groupName: "文章管理",
      //     name: "通知管理",
      //     icon: "ChatLineSquare",
      //     info: "Tickets/文章管理/通知管理",
      //     keepAlive: true,
      //     role: "admin"
      //   },
      //   component: () => import("@/views/page/article/notify.vue"),
      // },
      // {
      //   path: "/advertise",
      //   name: "advertise",
      //   meta: {
      //     group: "Notebook",
      //     groupName: "文章管理",
      //     name: "广告管理",
      //     icon: "Position",
      //     info: "Notebook/文章管理/广告管理",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/article/advertise.vue"),
      // },


      // 商城设置
      {
        path: "/shop",
        name: "shop",
        meta: {
          group: "Iphone",
          groupName: "商城设置",
          name: "首页设置",
          icon: "Shop",
          info: "Iphone/商城设置/首页设置",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/shop/shop.vue"),
      },
      {
        path: "/service",
        name: "service",
        meta: {
          group: "Iphone",
          groupName: "商城设置",
          name: "服务信息",
          icon: "Headset",
          info: "Iphone/商城设置/服务信息",
          keepAlive: false,
          role: "admin"
        },
        component: () => import("@/views/page/shop/service.vue"),
      },
      {
        path: "/agreement",
        name: "agreement",
        meta: {
          group: "Iphone",
          groupName: "商城设置",
          name: "用户协议",
          icon: "DocumentCopy",
          info: "Iphone/商城设置/用户协议",
          keepAlive: false,
          role: "admin",
          class: 1
        },
        component: () => import("@/views/page/shop/agreement.vue"),
      },
      {
        path: "/privacy",
        name: "privacy",
        meta: {
          group: "Iphone",
          groupName: "商城设置",
          name: "隐私协议",
          icon: "Document",
          info: "Iphone/商城设置/隐私协议",
          keepAlive: false,
          role: "admin",
          class: 2
        },
        component: () => import("@/views/page/shop/privacy.vue"),
      },
      {
        path: "/intro",
        name: "intro",
        meta: {
          group: "Iphone",
          groupName: "商城设置",
          name: "商城介绍",
          icon: "Tickets",
          info: "Iphone/商城设置/商城介绍",
          keepAlive: false,
          role: "admin",
          class: 3
        },
        component: () => import("@/views/page/shop/intro.vue"),
      },
      // {
      //   path: "/version",
      //   name: "version",
      //   meta: {
      //     group: "Iphone",
      //     groupName: "商城设置",
      //     name: "APP版本",
      //     icon: "Cellphone",
      //     info: "Iphone/商城设置/APP版本管理",
      //     keepAlive: true,
      //   },
      //   component: () => import("@/views/page/shop/version.vue"),
      // },
      // 设置
      {
        path: "/setting",
        name: "setting",
        meta: {
          group: "",
          name: "设置",
          icon: "Setting",
          info: "setting/设置中心",
          keepAlive: false,
          role: "admin/caiwu"
        },
        component: () => import("@/views/page/setting/setting.vue"),
      },
    ],
  },
  {
    path: "/:pathMacth(.*)",
    component: notpath,
  },
];

export default routes;
